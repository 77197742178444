<template>
    <div v-if="canDisplayProfileReputation" class="profile-reputation">
        <ProfileRating v-if="hasAppraisalWithRates" :stats="stats" />
        <ProfileRecommendations v-else-if="hasRecommendations" :recommendations-count="stats.recommendationsCount" />
        <ProfileBadge :first-name="firstName" :badges="badges" />
    </div>
</template>

<script setup lang="ts">
    import ProfileBadge from './reputation/ProfileBadge.vue';
    import ProfileRating from './reputation/ProfileRating.vue';
    import ProfileRecommendations from './reputation/ProfileRecommendations.vue';
    import type {ProfileBadgeEnum} from '../types';

    const props = defineProps<{
        firstName: string;
        stats: {
            rating: number;
            appraisalsWithRatesCount: number;
            invalidatedCharter: boolean;
            recommendationsCount: number;
        };
        badges: ProfileBadgeEnum[];
    }>();

    const canDisplayProfileReputation = !props.stats.invalidatedCharter;
    const hasAppraisalWithRates = props.stats.appraisalsWithRatesCount > 0;
    const hasRecommendations = props.stats.recommendationsCount > 0;
</script>

<style scoped lang="scss">
    .profile-reputation {
        display: flex;
        flex-flow: row wrap;
        gap: var(--joy-core-spacing-2);
    }
</style>

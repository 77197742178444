/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { Barometer } from './';
import {
    BarometerFromJSON,
    BarometerFromJSONTyped,
    BarometerToJSON,
} from './';
import type { FreelancerProfile } from './';
import {
    FreelancerProfileFromJSON,
    FreelancerProfileFromJSONTyped,
    FreelancerProfileToJSON,
} from './';
import type { LandingPageContent } from './';
import {
    LandingPageContentFromJSON,
    LandingPageContentFromJSONTyped,
    LandingPageContentToJSON,
} from './';
import type { LandingPageContentTextualContent } from './';
import {
    LandingPageContentTextualContentFromJSON,
    LandingPageContentTextualContentFromJSONTyped,
    LandingPageContentTextualContentToJSON,
} from './';
import type { Location } from './';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LandingPageContentWithHighlightedProfiles
 */
export interface LandingPageContentWithHighlightedProfiles extends LandingPageContent {
    /**
     * 
     * @type {Array<FreelancerProfile>}
     * @memberof LandingPageContentWithHighlightedProfiles
     */
    newFreelancerProfiles?: Array<FreelancerProfile>;
    /**
     * 
     * @type {Array<FreelancerProfile>}
     * @memberof LandingPageContentWithHighlightedProfiles
     */
    experiencedFreelancerProfiles?: Array<FreelancerProfile>;
    /**
     * 
     * @type {string}
     * @memberof LandingPageContentWithHighlightedProfiles
     */
    searchMoreExperiencedProfilesUrl?: string;
    /**
     * 
     * @type {Array<FreelancerProfile>}
     * @memberof LandingPageContentWithHighlightedProfiles
     */
    availableFreelancerProfiles?: Array<FreelancerProfile>;
    /**
     * 
     * @type {string}
     * @memberof LandingPageContentWithHighlightedProfiles
     */
    searchMoreAvailableProfilesUrl?: string;
}

/**
 * Check if a given object implements the LandingPageContentWithHighlightedProfiles interface.
 */
export function instanceOfLandingPageContentWithHighlightedProfiles(value: object): value is LandingPageContentWithHighlightedProfiles {
    return true;
}

export function LandingPageContentWithHighlightedProfilesFromJSON(json: any): LandingPageContentWithHighlightedProfiles {
    return LandingPageContentWithHighlightedProfilesFromJSONTyped(json, false);
}

export function LandingPageContentWithHighlightedProfilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageContentWithHighlightedProfiles {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
    }
    return {
        ...LandingPageContentFromJSONTyped(json, ignoreDiscriminator),
        'newFreelancerProfiles': json['newFreelancerProfiles'] == null ? undefined : ((json['newFreelancerProfiles'] as Array<any>).map(FreelancerProfileFromJSON)),
        'experiencedFreelancerProfiles': json['experiencedFreelancerProfiles'] == null ? undefined : ((json['experiencedFreelancerProfiles'] as Array<any>).map(FreelancerProfileFromJSON)),
        'searchMoreExperiencedProfilesUrl': json['searchMoreExperiencedProfilesUrl'] == null ? undefined : json['searchMoreExperiencedProfilesUrl'],
        'availableFreelancerProfiles': json['availableFreelancerProfiles'] == null ? undefined : ((json['availableFreelancerProfiles'] as Array<any>).map(FreelancerProfileFromJSON)),
        'searchMoreAvailableProfilesUrl': json['searchMoreAvailableProfilesUrl'] == null ? undefined : json['searchMoreAvailableProfilesUrl'],
    };
}

export function LandingPageContentWithHighlightedProfilesToJSON(value?: LandingPageContentWithHighlightedProfiles | null, ignoreDiscriminator?: boolean): any {
    if (value == null) {
        return value;
    }
    if (!ignoreDiscriminator) {
    }
    return {
        ...LandingPageContentToJSON(value, true),
        'newFreelancerProfiles': value['newFreelancerProfiles'] == null ? undefined : ((value['newFreelancerProfiles'] as Array<any>).map((v) => FreelancerProfileToJSON(v))),
        'experiencedFreelancerProfiles': value['experiencedFreelancerProfiles'] == null ? undefined : ((value['experiencedFreelancerProfiles'] as Array<any>).map((v) => FreelancerProfileToJSON(v))),
        'searchMoreExperiencedProfilesUrl': value['searchMoreExperiencedProfilesUrl'],
        'availableFreelancerProfiles': value['availableFreelancerProfiles'] == null ? undefined : ((value['availableFreelancerProfiles'] as Array<any>).map((v) => FreelancerProfileToJSON(v))),
        'searchMoreAvailableProfilesUrl': value['searchMoreAvailableProfilesUrl'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { Breadcrumb } from './';
import {
    BreadcrumbFromJSON,
    BreadcrumbFromJSONTyped,
    BreadcrumbToJSON,
} from './';
import type { PaginatedProfileList } from './';
import {
    PaginatedProfileListFromJSON,
    PaginatedProfileListFromJSONTyped,
    PaginatedProfileListToJSON,
} from './';

/**
 * 
 * @export
 * @interface TagSearchResponse
 */
export interface TagSearchResponse {
    /**
     * 
     * @type {Array<Breadcrumb>}
     * @memberof TagSearchResponse
     */
    breadcrumbs?: Array<Breadcrumb>;
    /**
     * 
     * @type {PaginatedProfileList}
     * @memberof TagSearchResponse
     */
    profileList?: PaginatedProfileList;
}

/**
 * Check if a given object implements the TagSearchResponse interface.
 */
export function instanceOfTagSearchResponse(value: object): value is TagSearchResponse {
    return true;
}

export function TagSearchResponseFromJSON(json: any): TagSearchResponse {
    return TagSearchResponseFromJSONTyped(json, false);
}

export function TagSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'breadcrumbs': json['breadcrumbs'] == null ? undefined : ((json['breadcrumbs'] as Array<any>).map(BreadcrumbFromJSON)),
        'profileList': json['profileList'] == null ? undefined : PaginatedProfileListFromJSON(json['profileList']),
    };
}

export function TagSearchResponseToJSON(value?: TagSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'breadcrumbs': value['breadcrumbs'] == null ? undefined : ((value['breadcrumbs'] as Array<any>).map((v) => BreadcrumbToJSON(v))),
        'profileList': PaginatedProfileListToJSON(value['profileList']),
    };
}


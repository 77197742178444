/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextContent
 */
export interface TextContent {
    /**
     * 
     * @type {string}
     * @memberof TextContent
     */
    value?: string;
}

/**
 * Check if a given object implements the TextContent interface.
 */
export function instanceOfTextContent(value: object): value is TextContent {
    return true;
}

export function TextContentFromJSON(json: any): TextContent {
    return TextContentFromJSONTyped(json, false);
}

export function TextContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextContent {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function TextContentToJSON(value?: TextContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Breadcrumb
 */
export interface Breadcrumb {
    /**
     * 
     * @type {string}
     * @memberof Breadcrumb
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof Breadcrumb
     */
    url?: string;
}

/**
 * Check if a given object implements the Breadcrumb interface.
 */
export function instanceOfBreadcrumb(value: object): value is Breadcrumb {
    return true;
}

export function BreadcrumbFromJSON(json: any): Breadcrumb {
    return BreadcrumbFromJSONTyped(json, false);
}

export function BreadcrumbFromJSONTyped(json: any, ignoreDiscriminator: boolean): Breadcrumb {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'] == null ? undefined : json['label'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function BreadcrumbToJSON(value?: Breadcrumb | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'url': value['url'],
    };
}


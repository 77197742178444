<template>
    <MaltImg :src="photoUrl" :alt="alt || 'photo'" :width="360" :height="360" :quality="90" :modifiers="modifiers" />
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {computed} from 'vue';

    interface ProfilePhotoProps {
        photoUrl: string;
        alt?: string;
        faceCrop?: boolean;
    }

    const props = defineProps<ProfilePhotoProps>();

    const defaultImageModifiers = {
        gravity: 'face',
    };
    const modifiers = computed(() => {
        if (props.faceCrop) {
            return {
                ...defaultImageModifiers,
                func: 'face',
                face_margin: 60,
            };
        }
        return defaultImageModifiers;
    });
</script>

<style lang="scss" scoped>
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
</style>

<template>
    <div class="profile-card__header-background-container">
        <ProfilePhoto v-if="!hasPortfolio || compactMode" :photo-url="photoUrl" :alt="alt" face-crop />
        <ProfilePortfolio v-else :portfolio="portfolio" :photo-url="photoUrl" />
    </div>
</template>

<script setup lang="ts">
    import ProfilePortfolio from '@profile-card/components/profile-card/internal/ProfilePortfolio.vue';
    import ProfilePhoto from '@profile-card/components/profile-card/internal/ProfilePhoto.vue';
    import type {PortfolioItem} from '@profile-card/components/profile-card/types';
    import {computed, onMounted, ref} from 'vue';

    const props = defineProps<{
        photoUrl: string;
        alt: string;
        portfolio: PortfolioItem[];
        compactMode?: boolean;
    }>();

    const hasPortfolio = computed(() => props.portfolio.length > 0);
</script>

<style scoped lang="scss">
    .profile-card {
        &__header-background-container {
            position: absolute;
            z-index: -1;
            top: -1px;
            left: -1px;
            display: flex;
            align-items: center;
            overflow: hidden;
            // we add 2px to ensure there is no space between the contained image and the profile card border
            width: calc(100% + 2px);
            height: 100%;
        }
    }
</style>

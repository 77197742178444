/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PricePerTown
 */
export interface PricePerTown {
    /**
     * 
     * @type {string}
     * @memberof PricePerTown
     */
    city?: string;
    /**
     * 
     * @type {number}
     * @memberof PricePerTown
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof PricePerTown
     */
    numberOfFreelancers?: number;
}

/**
 * Check if a given object implements the PricePerTown interface.
 */
export function instanceOfPricePerTown(value: object): value is PricePerTown {
    return true;
}

export function PricePerTownFromJSON(json: any): PricePerTown {
    return PricePerTownFromJSONTyped(json, false);
}

export function PricePerTownFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricePerTown {
    if (json == null) {
        return json;
    }
    return {
        
        'city': json['city'] == null ? undefined : json['city'],
        'price': json['price'] == null ? undefined : json['price'],
        'numberOfFreelancers': json['numberOfFreelancers'] == null ? undefined : json['numberOfFreelancers'],
    };
}

export function PricePerTownToJSON(value?: PricePerTown | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'city': value['city'],
        'price': value['price'],
        'numberOfFreelancers': value['numberOfFreelancers'],
    };
}


<template>
    <VJoyTooltip v-if="displayAvailability" :text="tooltipText" variant="primary">
        <VJoyAvailability
            :status="status"
            :is-partial="isPartial"
            :label="$t('profile.availability.indicator.explicit.label.v2', {availability: status})"
        />
    </VJoyTooltip>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useFormat, useTranslation} from '#imports';
    import type {AvailabilityFrequency, AvailabilityStatus, WorkAvailability} from '../types';
    import {VJoyTooltip, VJoyAvailability} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {formatDate} = useFormat();

    const props = defineProps<{
        status: AvailabilityStatus;
        workAvailability: WorkAvailability;
        nextAvailabilityDate?: Date;
        frequency?: AvailabilityFrequency;
    }>();

    const displayAvailability = computed(() => !['AVAILABLE', 'NOT_AVAILABLE'].includes(props.status));
    const isPartial = computed(() => props.workAvailability === 'PARTIAL');
    const tooltipText = computed(() => {
        if (props.frequency) {
            return t('profile.availability.indicator.newtooltip', {
                availability: props.status,
                workAvailability: props.workAvailability,
                nextAvailabilityDate: formatDate(props.nextAvailabilityDate),
                frequency: t(`profile.frequency.text.${props.frequency}`),
            });
        } else {
            return t('profile.availability.indicator.tooltip', {
                availability: props.status,
                workAvailability: props.workAvailability,
                nextAvailabilityDate: formatDate(props.nextAvailabilityDate),
            });
        }
    });
</script>

/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { PricePerTown } from './';
import {
    PricePerTownFromJSON,
    PricePerTownFromJSONTyped,
    PricePerTownToJSON,
} from './';

/**
 * 
 * @export
 * @interface Barometer
 */
export interface Barometer {
    /**
     * 
     * @type {number}
     * @memberof Barometer
     */
    averagePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Barometer
     */
    averageRating?: number;
    /**
     * 
     * @type {number}
     * @memberof Barometer
     */
    availableFreelancers?: number;
    /**
     * 
     * @type {Array<PricePerTown>}
     * @memberof Barometer
     */
    averagePricesPerTown?: Array<PricePerTown>;
    /**
     * 
     * @type {Array<PricePerTown>}
     * @memberof Barometer
     */
    relevantPricesPerTown?: Array<PricePerTown>;
}

/**
 * Check if a given object implements the Barometer interface.
 */
export function instanceOfBarometer(value: object): value is Barometer {
    return true;
}

export function BarometerFromJSON(json: any): Barometer {
    return BarometerFromJSONTyped(json, false);
}

export function BarometerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Barometer {
    if (json == null) {
        return json;
    }
    return {
        
        'averagePrice': json['averagePrice'] == null ? undefined : json['averagePrice'],
        'averageRating': json['averageRating'] == null ? undefined : json['averageRating'],
        'availableFreelancers': json['availableFreelancers'] == null ? undefined : json['availableFreelancers'],
        'averagePricesPerTown': json['averagePricesPerTown'] == null ? undefined : ((json['averagePricesPerTown'] as Array<any>).map(PricePerTownFromJSON)),
        'relevantPricesPerTown': json['relevantPricesPerTown'] == null ? undefined : ((json['relevantPricesPerTown'] as Array<any>).map(PricePerTownFromJSON)),
    };
}

export function BarometerToJSON(value?: Barometer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'averagePrice': value['averagePrice'],
        'averageRating': value['averageRating'],
        'availableFreelancers': value['availableFreelancers'],
        'averagePricesPerTown': value['averagePricesPerTown'] == null ? undefined : ((value['averagePricesPerTown'] as Array<any>).map((v) => PricePerTownToJSON(v))),
        'relevantPricesPerTown': value['relevantPricesPerTown'] == null ? undefined : ((value['relevantPricesPerTown'] as Array<any>).map((v) => PricePerTownToJSON(v))),
    };
}


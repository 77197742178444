<template>
    <section
        :id="id"
        class="profile-card"
        :class="{
            'profile-card--admin': borderStyle === 'ADMIN',
            'profile-card--shortlist-selected': borderStyle === 'SELECTED',
            'profile-card--compact': compactMode,
            'profile-card--skeleton': skeleton,
            'profile-card--link': !!linkHref,
        }"
    >
        <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
        <Component :is="linkHref ? 'a' : 'div'" :href="linkHref" :target="linkTarget" class="profile-card__link-without-style" :aria-label="altText">
            <header class="profile-card__header">
                <div class="profile-card__header-top">
                    <div>
                        <ProfileAvailabilityBadge
                            v-if="!compactMode"
                            :status="profile.availability.status"
                            :work-availability="profile.availability.workAvailability"
                            :next-availability-date="profile.availability.nextAvailabilityDate"
                            :frequency="profile.availability.frequency"
                        />
                    </div>
                    <div>
                        <slot name="top-right" />
                    </div>
                </div>
                <template v-if="!skeleton">
                    <ProfileCardHeaderBackground
                        :photo-url="photoUrl"
                        :alt="altText"
                        :portfolio="profile.portfolio ?? []"
                        :compact-mode="compactMode"
                    />
                </template>
                <slot name="header" />
                <div class="profile-card__identity">
                    <ProfileName :first-name="profile.firstName" :last-name-normalized="profile.lastNameNormalized" />
                    <ProfileLocation
                        v-if="profile.location.city"
                        icon-color="white"
                        :location="profile.location"
                        :first-name="profile.firstName"
                        :target-city="profile.targetCity"
                        :compact-mode="compactMode"
                    />
                </div>
            </header>
            <div class="profile-card__body">
                <ProfilePrice v-if="profile.price" class="profile-card__price" :price="profile.price" />
                <ProfileHeadline :headline="profile.headline" :compact-mode="compactMode" />
                <ProfileReputation class="profile-card__reputation" :first-name="profile.firstName" :stats="profile.stats" :badges="profile.badges" />
                <ProfileSkills :skills="profile.skills" :compact-mode="compactMode" />
            </div>
        </Component>
    </section>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import ProfileAvailabilityBadge from './internal/ProfileAvailability.vue';
    import ProfileHeadline from './internal/ProfileHeadline.vue';
    import ProfileLocation from './internal/ProfileLocation.vue';
    import ProfileName from './internal/ProfileName.vue';
    import ProfilePhoto from './internal/ProfilePhoto.vue';
    import ProfilePortfolio from './internal/ProfilePortfolio.vue';
    import ProfilePrice from './internal/ProfilePrice.vue';
    import ProfileReputation from './internal/ProfileReputation.vue';
    import ProfileSkills from './internal/ProfileSkills.vue';
    import type {BorderStyleEnum, Profile} from './types';
    import ProfileCardHeaderBackground from '@profile-card/components/profile-card/internal/ProfileCardHeaderBackground.vue';

    const props = defineProps<{
        id?: string;
        borderStyle?: BorderStyleEnum;
        skeleton?: boolean;
        compactMode?: boolean;
        linkHref?: string;
        linkTarget?: '_blank';
        profile: Profile;
    }>();

    const photoUrl = computed(() => props.profile.photoUrl ?? '/anonymous');
    const altText = computed(() => `${props.profile.firstName} ${props.profile.lastNameNormalized} - ${props.profile.headline}`);
</script>

<style lang="scss" scoped>
    .profile-card {
        --profile-card-width: 290px;

        flex: 1 0 auto;
        border: 1px solid var(--joy-color-neutral-30);
        background-color: #fff;
        border-radius: var(--joy-core-radius-3);

        &__header {
            position: relative;
            z-index: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            height: calc(var(--profile-card-width) / 1.3);
            padding: var(--joy-core-spacing-4) var(--joy-core-spacing-4) 25px;
            color: #fff;
            overflow: hidden;
            border-top-left-radius: calc(var(--joy-core-radius-3) - 1px);
            border-top-right-radius: calc(var(--joy-core-radius-3) - 1px);
        }
        &__header-top {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            width: 100%;
        }

        &__identity {
            width: 100%;
        }

        &__identity::after {
            content: '';
            z-index: -1;
            position: absolute;
            bottom: 1px;
            left: -1px;
            width: calc(100% + 2px);
            height: 100px;
            opacity: 0.4;
            background: linear-gradient(to top, var(--joy-color-neutral-40) 10%, transparent);
        }

        &__body {
            padding: var(--joy-core-spacing-6) var(--joy-core-spacing-4) var(--joy-core-spacing-4);
            position: relative;
        }

        &--admin {
            border: 1px solid var(--joy-color-quaternary-50);
        }

        &--shortlist-selected {
            border: 1px solid var(--joy-color-secondary-50);
        }

        &--link:hover {
            box-shadow: var(--joy-core-elevation-2);
        }

        &__price {
            position: absolute;
            top: calc(-1 * var(--joy-core-spacing-3));
        }

        &__link-without-style {
            text-decoration: none;
            color: inherit;
        }

        &__reputation {
            margin: var(--joy-core-spacing-4) 0;
        }

        &--compact {
            .profile-card {
                &__header {
                    height: 190px;
                }

                &__body {
                    padding-left: var(--joy-core-spacing-4);
                    padding-right: var(--joy-core-spacing-4);
                }
            }
        }

        &--skeleton {
            .profile-card {
                &__header {
                    background: linear-gradient(
                        110deg,
                        var(--joy-color-neutral-20) 8%,
                        var(--joy-color-neutral-10) 18%,
                        var(--joy-color-neutral-20) 33%
                    );
                    background-size: 200% 100%;
                    animation: 1.5s shine linear infinite;
                }
                &__body,
                &__header > * {
                    visibility: hidden;
                }
            }
            @keyframes shine {
                to {
                    background-position-x: -200%;
                }
            }
        }
    }
</style>

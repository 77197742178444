<template>
    <ClientOnly>
        <Carousel :value="portfolioItems" :num-visible="1" :num-scroll="1" circular :show-indicators="false">
            <template #item="slotProps">
                <ProfilePhoto :photo-url="slotProps.data.url" :alt="slotProps.data.title" :face-crop="slotProps.data.isProfilePhoto" />
            </template>
            <template #previcon>
                <VJoyIcon color="white" name="arrow-left" size="small" role="button" />
            </template>
            <template #nexticon>
                <VJoyIcon name="arrow-right" color="white" size="small" role="button" />
            </template>
        </Carousel>
        <template #fallback>
            <ProfilePhoto :photo-url="photoUrl" face-crop />
        </template>
    </ClientOnly>
</template>

<script setup lang="ts">
    import ProfilePhoto from './ProfilePhoto.vue';
    import Carousel from 'primevue/carousel';
    import type {ComputedRef} from 'vue';
    import {computed} from 'vue';
    import type {PortfolioItem} from '../types';
    import {VJoyIcon} from '@maltjoy/core-vue';

    interface ProfilePhotoProps {
        photoUrl: string;
        portfolio: PortfolioItem[];
    }

    const props = defineProps<ProfilePhotoProps>();

    type PortfolioCarouselItem = PortfolioItem & {isProfilePhoto: boolean};

    const portfolioItems: ComputedRef<PortfolioCarouselItem[]> = computed(() => [
        {
            title: '',
            url: props.photoUrl,
            isProfilePhoto: true,
        },
        ...props.portfolio.map((item) => ({
            ...item,
            isProfilePhoto: false,
        })),
    ]);
</script>

<style lang="scss" scoped>
    :deep(.p-carousel-prev-button),
    :deep(.p-carousel-next-button) {
        position: absolute;
        z-index: 1;

        &.p-button-text.p-button-secondary:enabled:hover {
            background: var(--joy-color-secondary-30);
        }
    }
    :deep(.p-carousel-prev-button) {
        left: 10px;
    }
    :deep(.p-carousel-next-button) {
        right: 10px;
    }
    :deep(.p-carousel-viewport) {
        height: 100% !important;
    }
</style>

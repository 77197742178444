/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { Barometer } from './';
import {
    BarometerFromJSON,
    BarometerFromJSONTyped,
    BarometerToJSON,
} from './';
import type { FreelancerProfile } from './';
import {
    FreelancerProfileFromJSON,
    FreelancerProfileFromJSONTyped,
    FreelancerProfileToJSON,
} from './';
import type { LandingPageContent } from './';
import {
    LandingPageContentFromJSON,
    LandingPageContentFromJSONTyped,
    LandingPageContentToJSON,
} from './';
import type { LandingPageContentTextualContent } from './';
import {
    LandingPageContentTextualContentFromJSON,
    LandingPageContentTextualContentFromJSONTyped,
    LandingPageContentTextualContentToJSON,
} from './';
import type { Location } from './';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LandingPageContentWithProfiles
 */
export interface LandingPageContentWithProfiles extends LandingPageContent {
    /**
     * 
     * @type {Array<FreelancerProfile>}
     * @memberof LandingPageContentWithProfiles
     */
    profiles: Array<FreelancerProfile>;
}

/**
 * Check if a given object implements the LandingPageContentWithProfiles interface.
 */
export function instanceOfLandingPageContentWithProfiles(value: object): value is LandingPageContentWithProfiles {
    if (!('profiles' in value) || value['profiles'] === undefined) return false;
    return true;
}

export function LandingPageContentWithProfilesFromJSON(json: any): LandingPageContentWithProfiles {
    return LandingPageContentWithProfilesFromJSONTyped(json, false);
}

export function LandingPageContentWithProfilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageContentWithProfiles {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
    }
    return {
        ...LandingPageContentFromJSONTyped(json, ignoreDiscriminator),
        'profiles': ((json['profiles'] as Array<any>).map(FreelancerProfileFromJSON)),
    };
}

export function LandingPageContentWithProfilesToJSON(value?: LandingPageContentWithProfiles | null, ignoreDiscriminator?: boolean): any {
    if (value == null) {
        return value;
    }
    if (!ignoreDiscriminator) {
    }
    return {
        ...LandingPageContentToJSON(value, true),
        'profiles': ((value['profiles'] as Array<any>).map((v) => FreelancerProfileToJSON(v))),
    };
}


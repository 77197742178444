<template>
    <div class="cms-carousel" :class="{'swiper-ready': isReady}">
        <button class="swiper-button swiper-button-prev">
            <VJoyIcon name="chevron-left" color="primary" size="small" />
        </button>
        <Swiper
            ref="swiper"
            :modules="[Navigation]"
            :navigation="{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
            :space-between="20"
            :breakpoints="breakpoints || defaultBreakpoints"
            @swiper="onReady"
        >
            <SwiperSlide v-for="(item, i) in items" :key="i">
                <slot name="slide" :item="item" />
            </SwiperSlide>
        </Swiper>
        <button class="swiper-button swiper-button-next">
            <VJoyIcon name="chevron-right" color="primary" size="small" />
        </button>
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {VJoyIcon} from '@maltjoy/core-vue';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    // @ts-expect-error
    import {Navigation} from 'swiper';
    import 'swiper/css';

    const defaultBreakpoints = {
        0: {slidesPerView: 1},
        480: {slidesPerView: 2},
        768: {slidesPerView: 3},
        992: {slidesPerView: 4},
    };

    defineProps<{items: any[]; breakpoints?: any}>();

    const isReady = ref(false);
    function onReady() {
        isReady.value = true;
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .cms-carousel {
        position: relative;
        display: flex;
        -webkit-tap-highlight-color: transparent;
        transition: opacity var(--joy-transition-duration-default) var(--joy-transition-timing-function);
        opacity: 0;

        @include mq.screen_md() {
            margin: 0 -24px;
        }

        .swiper {
            position: relative;
            flex-grow: 1;
        }
        .swiper-wrapper {
            align-items: stretch;
        }
        .swiper-button {
            flex: none;
            width: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: all var(--joy-transition-duration-default) var(--joy-transition-timing-function);
            background: white;

            &-disabled {
                opacity: 0;
                // pointer-events: none;
            }
        }

        &.swiper-ready {
            opacity: 1;
        }
    }
</style>


/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  LandingPageContent,
} from '../models';
import {
    LandingPageContentFromJSON,
    LandingPageContentToJSON,
} from '../models';

export interface SearchLandingPagesRequest {
    familyOrCity: string;
    categoryOrCity?: string;
    subCategoryIdOrCity?: string;
    city?: string;
}

/**
 * 
 */
export class LandingPageSearchApi extends runtime.BaseAPI {

    /**
     */
    async searchLandingPagesRaw(requestParameters: SearchLandingPagesRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<LandingPageContent>> {
        if (requestParameters['familyOrCity'] == null) {
            throw new runtime.RequiredError(
                'familyOrCity',
                'Required parameter "familyOrCity" was null or undefined when calling searchLandingPages().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['familyOrCity'] != null) {
            queryParameters['familyOrCity'] = requestParameters['familyOrCity'];
        }

        if (requestParameters['categoryOrCity'] != null) {
            queryParameters['categoryOrCity'] = requestParameters['categoryOrCity'];
        }

        if (requestParameters['subCategoryIdOrCity'] != null) {
            queryParameters['subCategoryIdOrCity'] = requestParameters['subCategoryIdOrCity'];
        }

        if (requestParameters['city'] != null) {
            queryParameters['city'] = requestParameters['city'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<LandingPageContent>({
            path: `/cms/api/search/lp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=LandingPageContentFromJSON(response._data); }
        return response;
    }

    /**
     */
    async searchLandingPages(requestParameters: SearchLandingPagesRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<LandingPageContent> {
        const response = await this.searchLandingPagesRaw(requestParameters, initOverrides);
        return response._data;
    }

}

<template>
    <VJoyTooltip v-if="superMalterLevel" :text="$t('profile.card.badge.level.supermalter.tooltip', [props.firstName])">
        <VJoyBadgeLevel type="supermalter" :super-malter-level="superMalterLevel" />
    </VJoyTooltip>
    <VJoyBadgeLevel v-else-if="hasNewMalterBadge" type="new" />
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import type {ProfileBadgeEnum} from '../../types';
    import {VJoyTooltip, VJoyBadgeLevel} from '@maltjoy/core-vue';

    const props = defineProps<{
        firstName: string;
        badges: ProfileBadgeEnum[];
    }>();

    const superMalterLevel = computed(() => {
        if (props.badges.includes('SUPER_MALTER_3')) {
            return 3;
        }
        if (props.badges.includes('SUPER_MALTER_2')) {
            return 2;
        }
        if (props.badges.includes('SUPER_MALTER_1')) {
            return 1;
        }
        return null;
    });

    const hasNewMalterBadge = computed(() => props.badges.includes('NEW_MALTER'));
</script>

import {inject} from 'vue';
import {PRISMIC_CLIENT} from '~/app/keys';
import type {LandingPageContentWithProfiles, LandingPageContentWithHighlightedProfiles, SearchByTagRequest, TagSearchResponse} from '@api';
import {Configuration, LandingPageSearchApi, TagSearchApi, type FreelancerProfile} from '@api';
import type {Profile} from '@malt/nuxt-profile-card-module';
import type {AcquisitionTemplateDocument, AcquisitionPageDocument} from '@prismicio-types';

import {createError, useLocale, useSpanWrap} from '#imports';
import {usePrismicDocument} from '~/composables/content/usePrismicDocument';
import type {PrismicDocument} from '@prismicio/types';

export type AcquisitionPage = {
    landingPage: Promise<LandingPageContentWithHighlightedProfiles>;
    prismicTemplate?: Promise<AcquisitionTemplateDocument>;
    prismicContent?: Promise<AcquisitionPageDocument>;
};

export type UseAcquisitionPageParams = {
    familyOrCity: string;
    categoryOrCity: string;
    subCategoryIdOrCity: string;
    city: string;
};

export function useAcquisitionPage(routeParams: UseAcquisitionPageParams) {
    const searchApi = new LandingPageSearchApi(new Configuration({basePath: ''}));
    const client = inject(PRISMIC_CLIENT);
    const {locale} = useLocale();
    const lang = locale.value;
    const {fetchDocument: fetchPrismicTemplate} = usePrismicDocument('acquisitionTemplate');

    async function fetchPrismicDocument(documentType: string, uid?: string): Promise<PrismicDocument | undefined> {
        const getPrismicDocument = () => (uid ? client!.getByUID(documentType, uid, {lang}) : client!.getSingle(documentType, {lang}));
        return await useSpanWrap(getPrismicDocument, {httpMethod: 'prismic.get', kind: 'prismic', name: `[prismic] ${documentType}`});
    }

    async function fetchLandingPage(): Promise<LandingPageContentWithProfiles | LandingPageContentWithHighlightedProfiles> {
        try {
            const landingPage = await searchApi.searchLandingPages(routeParams);
            return landingPage;
        } catch (e) {
            throw createError({statusCode: 404, statusMessage: 'Error fetching acquisition page : ' + e});
        }
    }

    return {
        fetchLandingPage,
        fetchPrismicTemplate,
        fetchPrismicDocument,
    };
}

export function useTagPage() {
    const client = inject(PRISMIC_CLIENT);
    const {locale} = useLocale();
    const lang = locale.value;
    const searchApi = new TagSearchApi(new Configuration({basePath: ''}));

    async function fetchPrismicDocument(documentType: string, uid?: string): Promise<PrismicDocument | undefined> {
        const getPrismicDocument = () => (uid ? client!.getByUID(documentType, uid, {lang}) : client!.getSingle(documentType, {lang}));
        return await useSpanWrap(getPrismicDocument, {httpMethod: 'prismic.get', kind: 'prismic', name: `[prismic] ${documentType}`});
    }

    async function fetchLandingPage(searchRequest: SearchByTagRequest): Promise<TagSearchResponse> {
        try {
            const searchResult = await searchApi.searchByTag(searchRequest);
            return searchResult;
        } catch (e) {
            throw createError({statusCode: 404, statusMessage: 'Error fetching tag page : ' + e});
        }
    }

    return {
        fetchPrismicDocument,
        fetchLandingPage,
    };
}

export function formatProfile(profile: FreelancerProfile): Profile {
    return {
        ...profile,
        portfolio: [],
        skills: profile.skills?.map((skill) => skill.label),
        photoUrl: profile.photo?.absoluteUrl,
        price: {
            visibility: profile.price?.visibility,
            formattedValue: profile.price?.value?.formatted,
        },
    } as Profile;
}

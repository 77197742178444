
/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  TagSearchResponse,
} from '../models';
import {
    TagSearchResponseFromJSON,
    TagSearchResponseToJSON,
} from '../models';

export interface SearchByTagRequest {
    tagName: string;
    tagIdentifier: string;
    p?: number;
    size?: number;
}

/**
 * 
 */
export class TagSearchApi extends runtime.BaseAPI {

    /**
     * Search profiles by tag
     */
    async searchByTagRaw(requestParameters: SearchByTagRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<TagSearchResponse>> {
        if (requestParameters['tagName'] == null) {
            throw new runtime.RequiredError(
                'tagName',
                'Required parameter "tagName" was null or undefined when calling searchByTag().'
            );
        }

        if (requestParameters['tagIdentifier'] == null) {
            throw new runtime.RequiredError(
                'tagIdentifier',
                'Required parameter "tagIdentifier" was null or undefined when calling searchByTag().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['tagName'] != null) {
            queryParameters['tagName'] = requestParameters['tagName'];
        }

        if (requestParameters['tagIdentifier'] != null) {
            queryParameters['tagIdentifier'] = requestParameters['tagIdentifier'];
        }

        if (requestParameters['p'] != null) {
            queryParameters['p'] = requestParameters['p'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<TagSearchResponse>({
            path: `/cms/api/search/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=TagSearchResponseFromJSON(response._data); }
        return response;
    }

    /**
     * Search profiles by tag
     */
    async searchByTag(requestParameters: SearchByTagRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<TagSearchResponse> {
        const response = await this.searchByTagRaw(requestParameters, initOverrides);
        return response._data;
    }

}

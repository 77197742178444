/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeoSearchPage
 */
export interface SeoSearchPage {
    /**
     * 
     * @type {string}
     * @memberof SeoSearchPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SeoSearchPage
     */
    jobLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof SeoSearchPage
     */
    jobLabelSingular?: string;
    /**
     * 
     * @type {string}
     * @memberof SeoSearchPage
     */
    meta?: string;
    /**
     * 
     * @type {string}
     * @memberof SeoSearchPage
     */
    specialityLabel?: string;
}

/**
 * Check if a given object implements the SeoSearchPage interface.
 */
export function instanceOfSeoSearchPage(value: object): value is SeoSearchPage {
    return true;
}

export function SeoSearchPageFromJSON(json: any): SeoSearchPage {
    return SeoSearchPageFromJSONTyped(json, false);
}

export function SeoSearchPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoSearchPage {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'jobLabel': json['jobLabel'] == null ? undefined : json['jobLabel'],
        'jobLabelSingular': json['jobLabelSingular'] == null ? undefined : json['jobLabelSingular'],
        'meta': json['meta'] == null ? undefined : json['meta'],
        'specialityLabel': json['specialityLabel'] == null ? undefined : json['specialityLabel'],
    };
}

export function SeoSearchPageToJSON(value?: SeoSearchPage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'jobLabel': value['jobLabel'],
        'jobLabelSingular': value['jobLabelSingular'],
        'meta': value['meta'],
        'specialityLabel': value['specialityLabel'],
    };
}


<template>
    <Carousel class="profiles-carousel" :items="carouselItems">
        <template #slide="{item}">
            <ProfileCard :profile="formatProfile(item)" :link-href="item.url" />
        </template>
    </Carousel>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import Carousel from '~/components/Carousel.vue';
    import {ProfileCard} from '#components';
    import type {FreelancerProfile} from '@api';
    import {formatProfile} from '~/composables/acquisition/useAcquisitionPage';

    const {items} = defineProps<{items: FreelancerProfile[]}>();

    const carouselItems = ref(items as any[]);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profiles-carousel {
        .profile-card {
            height: 100%;

            .profile-location {
                white-space: unset;
            }
            .profile-name {
                color: white;
            }
        }
        .swiper-slide {
            padding-bottom: var(--joy-core-spacing-3);
        }
    }
</style>

/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { Barometer } from './';
import {
    BarometerFromJSON,
    BarometerFromJSONTyped,
    BarometerToJSON,
} from './';
import type { LandingPageContentTextualContent } from './';
import {
    LandingPageContentTextualContentFromJSON,
    LandingPageContentTextualContentFromJSONTyped,
    LandingPageContentTextualContentToJSON,
} from './';
import type { LandingPageContentWithHighlightedProfiles } from './';
import {
    LandingPageContentWithHighlightedProfilesFromJSON,
    LandingPageContentWithHighlightedProfilesFromJSONTyped,
    LandingPageContentWithHighlightedProfilesToJSON,
} from './';
import type { LandingPageContentWithProfiles } from './';
import {
    LandingPageContentWithProfilesFromJSON,
    LandingPageContentWithProfilesFromJSONTyped,
    LandingPageContentWithProfilesToJSON,
} from './';
import type { Location } from './';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LandingPageContent
 */
export interface LandingPageContent {
    /**
     * 
     * @type {string}
     * @memberof LandingPageContent
     */
    freelancerProfilesType: string;
    /**
     * 
     * @type {Location}
     * @memberof LandingPageContent
     */
    location?: Location;
    /**
     * 
     * @type {Barometer}
     * @memberof LandingPageContent
     */
    barometer?: Barometer;
    /**
     * 
     * @type {string}
     * @memberof LandingPageContent
     */
    seeMoreProfilesUrl?: string;
    /**
     * 
     * @type {LandingPageContentTextualContent}
     * @memberof LandingPageContent
     */
    textualContent: LandingPageContentTextualContent;
}

/**
 * Check if a given object implements the LandingPageContent interface.
 */
export function instanceOfLandingPageContent(value: object): value is LandingPageContent {
    if (!('freelancerProfilesType' in value) || value['freelancerProfilesType'] === undefined) return false;
    if (!('textualContent' in value) || value['textualContent'] === undefined) return false;
    return true;
}

export function LandingPageContentFromJSON(json: any): LandingPageContent {
    return LandingPageContentFromJSONTyped(json, false);
}

export function LandingPageContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageContent {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['freelancerProfilesType'] === 'HIGHLIGHTED') {
            return LandingPageContentWithHighlightedProfilesFromJSONTyped(json, true);
        }
        if (json['freelancerProfilesType'] === 'SIMPLE') {
            return LandingPageContentWithProfilesFromJSONTyped(json, true);
        }
    }
    return {
        
        'freelancerProfilesType': json['freelancerProfilesType'],
        'location': json['location'] == null ? undefined : LocationFromJSON(json['location']),
        'barometer': json['barometer'] == null ? undefined : BarometerFromJSON(json['barometer']),
        'seeMoreProfilesUrl': json['seeMoreProfilesUrl'] == null ? undefined : json['seeMoreProfilesUrl'],
        'textualContent': LandingPageContentTextualContentFromJSON(json['textualContent']),
    };
}

export function LandingPageContentToJSON(value?: LandingPageContent | null, ignoreDiscriminator?: boolean): any {
    if (value == null) {
        return value;
    }
    if (!ignoreDiscriminator) {
        if (value.freelancerProfilesType === 'HIGHLIGHTED') {
            return LandingPageContentWithHighlightedProfilesToJSON(value as LandingPageContentWithHighlightedProfiles);
        }
        if (value.freelancerProfilesType === 'SIMPLE') {
            return LandingPageContentWithProfilesToJSON(value as LandingPageContentWithProfiles);
        }
    }
    return {
        
        'freelancerProfilesType': value['freelancerProfilesType'],
        'location': LocationToJSON(value['location']),
        'barometer': BarometerToJSON(value['barometer']),
        'seeMoreProfilesUrl': value['seeMoreProfilesUrl'],
        'textualContent': LandingPageContentTextualContentToJSON(value['textualContent']),
    };
}


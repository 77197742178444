<template>
    <div class="profile-location" :class="{'profile-location--compact': compactMode}">
        <VJoyTooltip :text="locationTooltip" variant="primary">
            <VJoyIcon :color="iconColor" size="xsmall" name="location" />
            {{ $t('profile.card.header.location.exact.short', [null, location.city]) }}
        </VJoyTooltip>
    </div>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {computed} from 'vue';
    import {VJoyTooltip, VJoyIcon} from '@maltjoy/core-vue';

    const props = defineProps<{
        iconColor: 'neutral' | 'white';
        location: {
            city?: string;
            locationType: string;
        };
        firstName: string;
        targetCity?: string;
        compactMode?: boolean;
    }>();

    const {t} = useTranslation();

    const locationTooltip = computed(() => {
        let tooltip = '';
        if (props.location.city === props.targetCity) {
            if (['EXACT', 'NEAR'].includes(props.location.locationType)) {
                tooltip = t('profile.card.header.location.execute.onsite', [props.firstName]);
            } else {
                tooltip = t('profile.card.header.location.execute.remotely', [props.firstName]);
            }
        } else if (props.targetCity) {
            tooltip = t('profile.card.header.location.go.execute.onsite', [props.firstName, props.targetCity]);
        }
        return tooltip;
    });
</script>

<style lang="scss">
    .profile-location {
        font-family: var(--joy-font-family);
        font-weight: var(--joy-font-weight-normal);
        font-size: var(--joy-font-size-primary-300);
        line-height: var(--joy-line-height-large);
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;

        &--compact {
            -webkit-line-clamp: 2;
        }
    }
</style>

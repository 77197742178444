/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { Breadcrumb } from './';
import {
    BreadcrumbFromJSON,
    BreadcrumbFromJSONTyped,
    BreadcrumbToJSON,
} from './';
import type { LocalitySeoPage } from './';
import {
    LocalitySeoPageFromJSON,
    LocalitySeoPageFromJSONTyped,
    LocalitySeoPageToJSON,
} from './';
import type { SeoSearchPage } from './';
import {
    SeoSearchPageFromJSON,
    SeoSearchPageFromJSONTyped,
    SeoSearchPageToJSON,
} from './';
import type { SpecialitySeoPage } from './';
import {
    SpecialitySeoPageFromJSON,
    SpecialitySeoPageFromJSONTyped,
    SpecialitySeoPageToJSON,
} from './';
import type { TextContent } from './';
import {
    TextContentFromJSON,
    TextContentFromJSONTyped,
    TextContentToJSON,
} from './';

/**
 * 
 * @export
 * @interface LandingPageContentTextualContent
 */
export interface LandingPageContentTextualContent {
    /**
     * 
     * @type {Array<SpecialitySeoPage>}
     * @memberof LandingPageContentTextualContent
     */
    specialitySeoPages?: Array<SpecialitySeoPage>;
    /**
     * 
     * @type {Array<LocalitySeoPage>}
     * @memberof LandingPageContentTextualContent
     */
    localitySeoPages: Array<LocalitySeoPage>;
    /**
     * 
     * @type {SeoSearchPage}
     * @memberof LandingPageContentTextualContent
     */
    thisPage: SeoSearchPage;
    /**
     * 
     * @type {TextContent}
     * @memberof LandingPageContentTextualContent
     */
    reassurance: TextContent;
    /**
     * 
     * @type {TextContent}
     * @memberof LandingPageContentTextualContent
     */
    jobExplanationMedia: TextContent;
    /**
     * 
     * @type {TextContent}
     * @memberof LandingPageContentTextualContent
     */
    tipsTitle: TextContent;
    /**
     * 
     * @type {TextContent}
     * @memberof LandingPageContentTextualContent
     */
    tips: TextContent;
    /**
     * 
     * @type {number}
     * @memberof LandingPageContentTextualContent
     */
    numberOfClientsRounded: number;
    /**
     * 
     * @type {number}
     * @memberof LandingPageContentTextualContent
     */
    numberOfFreelancersRounded: number;
    /**
     * 
     * @type {Array<Breadcrumb>}
     * @memberof LandingPageContentTextualContent
     */
    breadcrumbs: Array<Breadcrumb>;
}

/**
 * Check if a given object implements the LandingPageContentTextualContent interface.
 */
export function instanceOfLandingPageContentTextualContent(value: object): value is LandingPageContentTextualContent {
    if (!('localitySeoPages' in value) || value['localitySeoPages'] === undefined) return false;
    if (!('thisPage' in value) || value['thisPage'] === undefined) return false;
    if (!('reassurance' in value) || value['reassurance'] === undefined) return false;
    if (!('jobExplanationMedia' in value) || value['jobExplanationMedia'] === undefined) return false;
    if (!('tipsTitle' in value) || value['tipsTitle'] === undefined) return false;
    if (!('tips' in value) || value['tips'] === undefined) return false;
    if (!('numberOfClientsRounded' in value) || value['numberOfClientsRounded'] === undefined) return false;
    if (!('numberOfFreelancersRounded' in value) || value['numberOfFreelancersRounded'] === undefined) return false;
    if (!('breadcrumbs' in value) || value['breadcrumbs'] === undefined) return false;
    return true;
}

export function LandingPageContentTextualContentFromJSON(json: any): LandingPageContentTextualContent {
    return LandingPageContentTextualContentFromJSONTyped(json, false);
}

export function LandingPageContentTextualContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageContentTextualContent {
    if (json == null) {
        return json;
    }
    return {
        
        'specialitySeoPages': json['specialitySeoPages'] == null ? undefined : ((json['specialitySeoPages'] as Array<any>).map(SpecialitySeoPageFromJSON)),
        'localitySeoPages': ((json['localitySeoPages'] as Array<any>).map(LocalitySeoPageFromJSON)),
        'thisPage': SeoSearchPageFromJSON(json['thisPage']),
        'reassurance': TextContentFromJSON(json['reassurance']),
        'jobExplanationMedia': TextContentFromJSON(json['jobExplanationMedia']),
        'tipsTitle': TextContentFromJSON(json['tipsTitle']),
        'tips': TextContentFromJSON(json['tips']),
        'numberOfClientsRounded': json['numberOfClientsRounded'],
        'numberOfFreelancersRounded': json['numberOfFreelancersRounded'],
        'breadcrumbs': ((json['breadcrumbs'] as Array<any>).map(BreadcrumbFromJSON)),
    };
}

export function LandingPageContentTextualContentToJSON(value?: LandingPageContentTextualContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'specialitySeoPages': value['specialitySeoPages'] == null ? undefined : ((value['specialitySeoPages'] as Array<any>).map((v) => SpecialitySeoPageToJSON(v))),
        'localitySeoPages': ((value['localitySeoPages'] as Array<any>).map((v) => LocalitySeoPageToJSON(v))),
        'thisPage': SeoSearchPageToJSON(value['thisPage']),
        'reassurance': TextContentToJSON(value['reassurance']),
        'jobExplanationMedia': TextContentToJSON(value['jobExplanationMedia']),
        'tipsTitle': TextContentToJSON(value['tipsTitle']),
        'tips': TextContentToJSON(value['tips']),
        'numberOfClientsRounded': value['numberOfClientsRounded'],
        'numberOfFreelancersRounded': value['numberOfFreelancersRounded'],
        'breadcrumbs': ((value['breadcrumbs'] as Array<any>).map((v) => BreadcrumbToJSON(v))),
    };
}


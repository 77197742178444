<template>
    <ul ref="container" class="profile-skills" data-testid="profile-skills-list" :class="{'profile-skills--compact': compactMode}">
        <li v-for="skill in skills" :key="skill">
            <VJoyTag
                :id="`skill-${slugify(skill)}-${id}`"
                class="profile-skills__tag"
                :label="skill"
                :value="skill"
                variant="secondary"
                size="xsmall"
            />
        </li>
    </ul>
</template>

<script setup lang="ts">
    import {VJoyTag} from '@maltjoy/core-vue';
    import {useId} from '#imports';

    const id = useId();

    defineProps<{
        skills: string[];
        compactMode?: boolean;
    }>();

    // remove spaces and special characters to generate the tag identifier
    function slugify(text: string) {
        return text.replaceAll(/\W/g, '_');
    }
</script>

<style scoped lang="scss">
    .profile-skills {
        --skill-badge-height: 26px;
        --profile-skills-row-gap: var(--joy-core-spacing-2);

        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        overflow: hidden;
        row-gap: var(--profile-skills-row-gap);
        column-gap: var(--joy-core-spacing-2);
        max-height: calc(2 * var(--skill-badge-height) + var(--profile-skills-row-gap));

        &--compact {
            max-height: calc(3 * var(--skill-badge-height) + 2 * var(--profile-skills-row-gap));
        }

        &__tag {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            max-width: var(--profile-card-skills-max-width);
            transition: none; // fix stuttering when the ProfileCard is used in primevue/carousel
        }
    }
</style>

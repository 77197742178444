/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocalitySeoPage
 */
export interface LocalitySeoPage {
    /**
     * 
     * @type {string}
     * @memberof LocalitySeoPage
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalitySeoPage
     */
    url?: string;
}

/**
 * Check if a given object implements the LocalitySeoPage interface.
 */
export function instanceOfLocalitySeoPage(value: object): value is LocalitySeoPage {
    return true;
}

export function LocalitySeoPageFromJSON(json: any): LocalitySeoPage {
    return LocalitySeoPageFromJSONTyped(json, false);
}

export function LocalitySeoPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalitySeoPage {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'] == null ? undefined : json['label'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function LocalitySeoPageToJSON(value?: LocalitySeoPage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'url': value['url'],
    };
}

